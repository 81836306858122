<template>
    <care-form
        :inputs="inputs"
        :errors="errors"
        :actions="actions"
        :item="client"
        :waiting="waiting"
    >
    </care-form>
    <!-- <v-btn v-t="{path:'Core.btn.create'}">
        ola mundo
    </v-btn> -->
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import Toast from '@libs/toast'

export default {
    data() {
        return {
            waiting: false,
            client: {},
            actions: [
                {
                    name: 'Core.btn.save',
                    onTop: false,
                    action: this.saveAction,
                },
                {
                    name: 'Core.btn.cancel',
                    onTop: false,
                    action: () => {
                        this.$emit('cancel')
                    },
                },
            ],
            errors: {},
            inputs: [
                {
                    type: 'text',
                    name: 'name',
                    show: true,
                    searchable: true,
                },
                {
                    type: 'text',
                    name: 'email',
                    show: true,
                    searchable: true,
                },
                {
                    type: 'telephone',
                    name: 'phone',
                    show: true,
                    searchable: false,
                    format: function (_, item) {
                        if (
                            !(
                                typeof item.phone === 'string' ||
                                item.phone instanceof String
                            )
                        )
                            return item.phone
                        const phone = PhoneNumber(item.phone)
                        if (phone.isValid()) {
                            return phone.getNumber('national')
                        }
                        return item.phone
                    },
                },
                {
                    type: 'date',
                    name: 'birthday',
                    show: false,
                    searchable: true,
                },
                {
                    type: 'code',
                    items: ['cpf', 'cnpj', 'ssn', 'ein'],
                },
                {
                    //custom type
                    type: 'address',
                    subItem: 'address',
                },
            ],
        }
    },
    methods: {
        saveAction() {
            const self = this
            const url = '/mclient/client'
            const createdItem = this.prepareDataToSend(
                this.client,
                this.inputs,
                {}
            )

            if (Object.keys(createdItem).length === 0) {
                Toast({
                    icon: 'info',
                    title: this.$t('Core.alerts.no_change'),
                })
                return
            }
            this.waiting = true
            this.post({
                url,
                data: createdItem,
                locale: '[ClientForm]-[Create]',
            })
                .then((response) => {
                    Toast({
                        icon: 'success',
                        title: self.$t('Core.alerts.created', {
                            item: self.$tc('Client.model.client', 1),
                        }),
                    })
                    self.client = response.data.data
                    self.$emit('input', response.data.data)
                })
                .catch((response) => {
                    if (response.status == 422) {
                        if (response.data.errors)
                            self.errors = response.data.errors
                        window.scrollTo(0, 0)
                    }
                })
                .finally(() => {
                    self.waiting = false
                })
        },
    },
    created() {
        if (this.inputs.some((item) => item.type == 'address')) {
            this.inputs.push(
                {
                    type: 'none',
                    name: 'country',
                    items: COUNTRIES_PHP,
                    show: false,
                    searchable: true,
                },
                {
                    type: 'none',
                    name: 'postalcode',
                    show: false,
                    searchable: false,
                },
                {
                    type: 'none',
                    name: 'state',
                    show: false,
                    searchable: true,
                },
                {
                    type: 'none',
                    name: 'city',
                    show: false,
                    searchable: true,
                },
                {
                    type: 'none',
                    name: 'district',
                    show: false,
                    searchable: true,
                },
                {
                    type: 'none',
                    name: 'street',
                    show: false,
                    searchable: false,
                },
                {
                    type: 'none',
                    name: 'number',
                    show: false,
                    searchable: false,
                },
                {
                    type: 'none',
                    name: 'complement',
                    show: false,
                    searchable: false,
                }
            )
        }
        if (this.inputs.some((item) => item.type == 'code')) {
            this.inputs.push(
                {
                    type: 'none',
                    name: 'type_code',
                    show: false,
                    searchable: true,
                },
                {
                    type: 'none',
                    name: 'code',
                    show: true,
                    searchable: true,
                },
                {
                    type: 'none',
                    name: 'secondary_code',
                    show: false,
                    searchable: true,
                }
            )
        }
    },
}
</script>

<style></style>
