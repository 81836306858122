import useLayoutStore from '@stores/layout'

export default (cntx: any) => {
    const i18n = cntx.i18n
    const pinia = cntx.pinia
    const layoutStore = useLayoutStore(pinia)
    for (const lang in i18n.messages) {
        const clone = i18n.messages[lang]
        clone['Client']['routes']['ClientManager'] = function (cntx: any) {
            const str = i18n.tc('Client.model.' + layoutStore.clientType, 2)
            return str
        }
        clone['Client']['routes']['Clients_Folder'] = function (cntx: any) {
            return i18n.t('Client.routes.Clients_Folder_T', {
                model: i18n.tc('Client.model.' + layoutStore.clientType, 2),
            })
        }
        i18n.setLocaleMessage(lang, clone)
    }
}
