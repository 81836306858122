<template>
    <div>
        <v-dialog
            :value="!!profileClient"
            @input="() => (profileClient = null)"
            max-width="500px"
        >
            <v-card>
                <v-card-title
                    class="text-h5"
                    v-t="{ path: 'Client.title.view_profile' }"
                ></v-card-title>
                <v-card-text>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody v-if="!!profileClient">
                                <tr>
                                    <td v-t="{ path: 'Core.params.name' }"></td>
                                    <td>
                                        {{ profileClient.user_profile.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        v-t="{ path: 'Core.params.email' }"
                                    ></td>
                                    <td>
                                        {{ profileClient.user_profile.email }}
                                    </td>
                                </tr>
                                <tr v-if="profileClient.user_profile.birthday">
                                    <td
                                        v-t="{ path: 'Core.params.birthday' }"
                                    ></td>
                                    <td>
                                        {{
                                            profileClient.user_profile.birthday
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="profileClient.type_code">
                                    <td
                                        v-t="{
                                            path:
                                                'Core.params.' +
                                                profileClient.type_code,
                                        }"
                                    >
                                        {{ profileClient.type_code }}
                                    </td>
                                    <td>{{ profileClient.code }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="() => (profileClient = null)"
                        v-t="{ path: 'Core.btn.close' }"
                    ></v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="attachUserDialog" max-width="400px">
            <v-card>
                <v-card-title class="text-h5">{{
                    $t('Client.title.attach_profile', {
                        model: $tc('Client.model.' + this.clientType, 1),
                    })
                }}</v-card-title>
                <v-card-text v-if="!!profileUser">
                    {{
                        $t('Client.sentence.attach_profile_1', {
                            type_code: $t(
                                'Core.params.' + profileUser.type_code
                            ),
                        })
                    }}:
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td v-t="{ path: 'Core.params.name' }"></td>
                                    <td>{{ profileUser.name }}</td>
                                </tr>
                                <tr>
                                    <td
                                        v-t="{ path: 'Core.params.email' }"
                                    ></td>
                                    <td>{{ profileUser.email }}</td>
                                </tr>
                                <tr v-if="profileUser.birthday">
                                    <td
                                        v-t="{ path: 'Core.params.birthday' }"
                                    ></td>
                                    <td>{{ profileUser.birthday }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-spacer></v-spacer>
                    {{
                        $t('Client.sentence.attach_profile_2', {
                            model: $tc('Client.model.' + this.clientType, 1),
                        })
                    }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary darken-1"
                        text
                        @click="attachUserDialog = !attachUserDialog"
                        v-t="{ path: 'Core.btn.close' }"
                    ></v-btn>
                    <v-btn
                        color="primary darken-1"
                        @click="attachClientToUser"
                        v-t="{ path: 'Core.btn.confirm' }"
                    ></v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createUserDialog" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Criar usuário</v-card-title>
                <v-card-text>
                    O usuário vai ser criado com as seguintes informações:
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td v-t="{ path: 'Core.params.name' }"></td>
                                    <td>{{ attachClient.name }}</td>
                                </tr>
                                <tr>
                                    <td
                                        v-t="{ path: 'Core.params.email' }"
                                    ></td>
                                    <td>{{ attachClient.email }}</td>
                                </tr>
                                <tr v-if="attachClient.birthday">
                                    <td
                                        v-t="{ path: 'Core.params.birthday' }"
                                    ></td>
                                    <td>{{ attachClient.birthday }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-spacer style="margin-top: 20px"></v-spacer>
                    Preencha uma senha para o acesso a área do cliente:
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    :append-icon="
                                        show1 ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    :rules="[rules.required, rules.min]"
                                    :type="show1 ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Senha"
                                    hint="Deve conter ao menos 8 caracteres"
                                    counter
                                    @click:append="show1 = !show1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="repassword"
                                    :append-icon="
                                        show2 ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    :rules="[rules.match]"
                                    :type="show2 ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Repita a senha"
                                    hint="Deve ser igual a senha anterior"
                                    @click:append="show2 = !show2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary darken-2"
                        text
                        @click="createUserDialog = !createUserDialog"
                        v-t="{ path: 'Core.btn.close' }"
                    ></v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="createUserForClient"
                        v-t="{ path: 'Core.btn.save' }"
                    ></v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                color="primary"
                size="128"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import useLayoutStore from '@stores/layout'
import { mapState } from 'pinia'
import Toast from '@libs/toast'

export default {
    data() {
        return {
            profileClient: null,
            profileUser: null,
            loading: false,
            createUserDialog: false,
            attachUserDialog: false,
            attachClient: false,

            show1: false,
            show2: false,

            password: '',
            repassword: '',
            rules: {
                required: (value) => !!value || 'Deve ser preenchido',
                min: (v) =>
                    v.length >= 8 ||
                    'A senha precisa ter ao menos 8 caracteres',
                match: (v) =>
                    (!!v && v) === this.password || 'As senhas não são iguais',
            },
        }
    },
    methods: {
        attachClientToUser() {
            const self = this
            this.loading = true
            this.attachUserDialog = false
            this.put({
                url:
                    '/mclient/client/' +
                    this.attachClient.id +
                    '/user/' +
                    this.profileUser.id,
                locale: '[Client - Attach User Related]',
            })
                .then((response) => {
                    self.$parent.$refs.tableCrud.refresh()
                    Toast({
                        icon: 'success',
                        title: self.$t('Client.alert.client_attached_user', {
                            model: self.$tc(
                                'Client.model.' + self.clientType,
                                1
                            ),
                            email: response.data.data.user_profile.email,
                        }),
                    })
                })
                .catch(() => {})
                .finally(() => {
                    self.loading = false
                })
        },
        createProfile(client) {
            const self = this
            this.loading = true
            this.attachClient = client
            this.get({
                url: '/mclient/client/' + this.attachClient.id + '/user',
                locale: '[Client - Get User Related]',
                silent: true,
            })
                .then((response) => {
                    self.attachUserDialog = true
                    self.profileUser = response.data.data
                })
                .catch((response) => {
                    console.error(response)
                    if (response.status == 404) {
                        self.createUserDialog = true
                        self.password = ''
                        self.repassword = ''
                        self.$refs.form.reset()
                    } else if (response.status == 422) {
                        Toast({
                            icon: 'error',
                            title: this.$t(
                                'Client.alert.add_profile_required',
                                {
                                    model: self.$tc(
                                        'Client.model.' + self.clientType,
                                        1
                                    ),
                                }
                            ),
                        })
                    }
                })
                .finally(() => {
                    self.loading = false
                })
        },
        createUserForClient() {
            if (!this.$refs.form.validate()) return
            const self = this
            this.createUserDialog = false
            this.loading = true
            this.post({
                url: '/mclient/client/' + this.attachClient.id + '/user',
                locale: '[Client - Create User Related]',
                data: { password: this.password },
            })
                .then((response) => {
                    self.$parent.$refs.tableCrud.refresh()
                    Toast({
                        icon: 'success',
                        title: self.$t('Client.alert.client_attached_user', {
                            model: self.$tc(
                                'Client.model.' + self.clientType,
                                1
                            ),
                            email: response.data.data.user_profile.email,
                        }),
                    })
                })
                .catch(() => {})
                .finally(() => {
                    self.loading = false
                })
        },
        openProfile(client) {
            this.profileClient = client
        },
    },
    computed: {
        clientType() {
            return useLayoutStore().clientType
        },
    },
}
</script>
