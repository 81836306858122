<template>
    <div style="position: relative">
        <transition-group name="list">
            <care-tab-crud
                class="list-item"
                v-if="client"
                v-show="test"
                :key="'client' + client.id"
                :url="'/mclient/client/' + client.id + '/contact'"
                model="Client.model.contact"
                :inputs="inputsContacts"
                :generalActions="contactSubActions"
            >
            </care-tab-crud>
            <care-tab-crud
                class="list-item"
                v-show="test == false"
                key="1"
                ref="tableCrud"
                url="/mclient/client"
                :model="'Client.model.' + clientType"
                :inputs="inputs"
                :rowActions="actions"
            >
            </care-tab-crud>
        </transition-group>
        <Client-Profile-Dialog ref="dialogs"> </Client-Profile-Dialog>
    </div>
</template>

<script>
import useLayoutStore from '@stores/layout'
import useUserStore from '@stores/user'
import { mapState } from 'pinia'
import PhoneNumber from 'awesome-phonenumber'

export default {
    name: 'ClientManagerPersistent',
    data() {
        return {
            test: false,
            client: null,
            inputs: [
                {
                    type: 'text',
                    name: 'name',
                    show: true,
                    searchable: true,
                    sortable: true,
                },
                {
                    type: 'text',
                    name: 'email',
                    show: true,
                    searchable: true,
                },
                {
                    type: 'telephone',
                    name: 'phone',
                    show: true,
                    searchable: false,
                    format: function (_, item) {
                        if (
                            !(
                                typeof item.phone === 'string' ||
                                item.phone instanceof String
                            )
                        )
                            return item.phone
                        const phone = PhoneNumber(item.phone)
                        if (phone.isValid()) {
                            return phone.getNumber('national')
                        }
                        return item.phone
                    },
                },
                {
                    type: 'date',
                    name: 'birthday',
                    show: false,
                    searchable: true,
                },
                {
                    type: 'code',
                    items: ['cpf', 'cnpj', 'ssn', 'ein'],
                },
                {
                    //custom type
                    type: 'address',
                    subItem: 'address',
                },
            ],
            inputsContacts: [
                {
                    type: 'text',
                    name: 'name',
                    nameTranslated: 'Client.params.contact_name',
                    show: true,
                    searchable: true,
                },
                {
                    type: 'select',
                    name: 'type',
                    nameTranslated: 'Client.params.type',
                    show: true,
                    searchable: true,

                    input: {
                        items: ['cell', 'email', 'address', 'contact'],
                        /**
                         * Observar que no caso do type search essa função recebe como parametro um objeto daquela modal
                         * enquanto essa função do tipo select recebe o item para ser formatado, traduzido, etc...
                         */
                        format: this.formatType,
                    },
                },
                {
                    type: 'textarea',
                    name: 'info',
                    nameTranslated: 'Client.params.data',
                    show: true,
                    searchable: false,
                    format: function (text, item) {
                        if (!text) return ''
                        if (text.length > 40) {
                            return text.substring(0, 37) + '...'
                        } else return text
                    },
                },
            ],
            actions: [
                {
                    name: 'Client.btn.manage_relation_psicoapp_forms',
                    onAction: this.manageRelationClientForms,
                    condition: (element) =>
                        element.policy.update &&
                        this.modules.indexOf('PsicoApp') > -1 &&
                        element.type_code != 'cnpj',
                    icon: 'mdi-head-question',
                },
                {
                    name: 'Client.btn.view_profile',
                    onAction: this.viewProfile,
                    condition: (element) => !!element.user_profile,
                    icon: 'mdi-account',
                },

                {
                    name: 'Client.btn.add_profile',
                    onAction: this.addProfile,
                    condition: (element) => !element.user_profile,
                    icon: 'mdi-account-plus',
                },
                {
                    name: 'Client.btn.show_contacts',
                    onAction: this.editContacts,
                    condition: (element) => element.policy.contacts,
                    icon: 'mdi-cellphone-text',
                },
            ],
            contactSubActions: [
                {
                    color: 'white',
                    onAction: this.back,
                    text: 'Client.btn.back',
                    icon: 'mdi-arrow-left-circle',
                },
            ],
        }
    },
    computed: {
        clientType() {
            return useLayoutStore().clientType
        },
        modules() {
            return useUserStore().modules
        },
    },
    methods: {
        manageRelationClientForms(client) {
            const route = {
                name: 'PsicoApp@ClientPsicoAppManager',
                query: {
                    client: client.id,
                    client_name: client.name,
                },
            }
            this.$router.push(route)
        },
        editContacts(item) {
            this.client = item
            this.test = true
        },
        back() {
            this.test = false
        },
        formatType(type) {
            return this.$t('Client.custom_params.' + type)
        },

        viewProfile(client) {
            this.$refs.dialogs.openProfile(client)
        },
        addProfile(client) {
            this.$refs.dialogs.createProfile(client)
        },
    },
    mounted() {
        const client = Number(this.$route.query.client)
        if (Number.isInteger(client)) {
            this.$refs.tableCrud.focusOnItem(client)
        }
    },
}
</script>

<style>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s;
    position: absolute;
    left: 0;
    right: 0;
}
.list-enter {
    opacity: 0;
    transform: translateX(+120px);
}
.list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(-120px);
}
</style>
